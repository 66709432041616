<template>
  <el-main class="rh-medform">
    <form-thesecreate :routes="thRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormThesecreate from "@/components/rh/formulaires/FormThesecreate";

export default {
  components: { "form-thesecreate": FormThesecreate },
  computed: {
    thRoutes() {
      return {
        toedit: {
          name: "med_theses_edit",
          params: {
            idmed: this.$route.params.idmed,
            idth: this.$store.state.th.theseLastCreatedId,
          },
        },
        tolist: {
          name: "med_theses",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
