<template>
  <div>
    <h1>Ajouter une Thèse</h1>
    <form-these :ismeddetail="ismeddetail" :isedit="false" ref="formTh">
    </form-these>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormThese from "@/components/rh/ui/form/FormThese";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-these": FormThese },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("th/resetThese", idMed);
  },
  computed: {
    theseCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.th.theseCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formTh.$refs.formThese.validate((valid) => {
        if (valid) {
          let th = this.$store.state.th.these;

          // enlever les espaces avant et après dans les string
          trimStringInObj(th);

          // vérifie si la these existe déjà pour le médecin
          const thInBD = this.$store.getters["th/TheseInBD"];
          const doublon =
            thInBD.filter(
              (m) =>
                m[1] == th.id_med &&
                m[2] == th.id_fac &&
                (!m[3] && !th.annee
                  ? m[4] == th.id_statut
                  : Number(m[3]) && Number(th.annee)
                  ? m[3] == th.annee
                  : false)
            ).length > 0;
          if (doublon) {
            const alertMsg = th.annee
              ? "Ce médecin a déjà une thèse enregistrée dans la même faculté pour la même année."
              : "Ce médecin a déjà une thèse enregistrée dans la même faculté avec le même statut.";
            this.$alert(alertMsg, "Attention", {
              confirmButtonText: "Fermer",
              dangerouslyUseHTMLString: true,
            });
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("th/createThese", th);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des thèses
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    theseCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.theseCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La thèse a été ajoutée à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du la thèse
        this.$router.push(this.routes.toedit);
      } else if (this.theseCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.theseCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.theseCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
